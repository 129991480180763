import { render, staticRenderFns } from "./WorkflowsReport.vue?vue&type=template&id=1e446d98&scoped=true&"
import script from "./WorkflowsReport.vue?vue&type=script&lang=js&"
export * from "./WorkflowsReport.vue?vue&type=script&lang=js&"
import style0 from "./WorkflowsReport.vue?vue&type=style&index=0&id=1e446d98&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e446d98",
  null
  
)

export default component.exports
import QSpace from 'quasar/src/components/space/QSpace.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QChatMessage from 'quasar/src/components/chat/QChatMessage.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSpace,QMenu,QChatMessage,QChip});
