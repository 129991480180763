<template>
  <div class="reports">
    <template v-if="!customChart && !gotoChart">
      <OverallChart
        :workflow-id="workflowId"
        :workflow-stage="workflowStage"
        :form-id="formId"
        :form-fields="formFields"
        :sla-settings="slaSettings"
        :workflow="workflow"
      />
    </template>

    <template v-else-if="gotoChart">
      <GotoChart
        :workflow-id="workflowId"
        :form-fields="formFields"
        :refresh="refresh"
        @refresh="refreshData"
      />
    </template>

    <template v-else-if="customChart">
      <BPMSChart
        :workflow-id="workflowId"
        :sla-settings="slaSettings"
        :repository-id="repositoryId"
        :repository-fields="repositoryFields"
        :workflow="workflow"
      />
    </template>
  </div>
</template>

<script>
import OverallChart from "./charts/OverAllChart.vue";
import BPMSChart from "./charts/BPMSChart.vue";
import GotoChart from "./charts/GotoChart.vue";

export default {
  name: "ReportCharts",

  components: { OverallChart, BPMSChart, GotoChart },

  props: {
    workflowId: {
      type: Number,
      default: 0,
    },

    workflowStage: {
      type: Array,
      default: () => [],
    },

    formId: {
      type: String,
      default: "",
    },

    formFields: {
      type: Array,
      default: () => [],
    },

    slaSettings: {
      type: Number,
      default: 0,
    },

    repositoryId: {
      type: Number,
      default: 0,
    },

    repositoryFields: {
      type: Array,
      default: () => [],
    },

    workflow: {
      type: Object,
      default: () => {},
    },

    refresh: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {};
  },

  computed: {
    customChart() {
      let origin = location.origin;
      if (
        origin === "https://demo.ezofis.com" ||
        origin === "http://52.172.32.88"
      ) {
        if (this.workflowId === 2077 || this.workflowId === 2092) {
          return true;
        }
      } else if (origin === "https://edmsuat.sobhaapps.com") {
        if (this.workflowId === 30 || this.workflowId === 55) {
          return true;
        }
      }
      return false;
    },

    gotoChart() {
      let origin = location.origin;
      if (
        origin === "https://demo.ezofis.com" ||
        origin === "http://52.172.32.88"
      ) {
        if (this.workflowId === 6) {
          return true;
        }
      } else if (
        origin === "https://app.ezofis.com1" ||
        origin === "https://appuat.ezofis.com"
      ) {
        return true;
      }
      return false;
    },
  },

  methods: {
    refreshData(value) {
      this.$emit("update:refresh", value);
    },
  },
};
</script>

<style lang="scss" scoped>
.reports {
  .link-style {
    cursor: pointer;
    text-decoration: none;
  }

  .link-style:hover {
    text-decoration: underline;
  }

  .wrapper {
    position: relative;
    height: calc(100vh - 208px);
  }

  .chart {
    height: 375px;
    padding: 10px;
    border-radius: 10px;
    background-color: var(--component-bg-color);
    border: 1px solid var(--component-bg-color);

    &.chartBox {
      height: 100px;
    }
  }

  .header {
    text-anchor: start;
    font-size: 15px;
    font-weight: 700;
    fill: rgb(55, 61, 63);
  }

  .header-top {
    border-bottom: 1px solid var(--divider-color);
    padding-bottom: 8px;
    margin-bottom: 8px;
  }

  .label {
    color: #a391cf !important;
  }

  .count {
    color: #8162cc;
    font-weight: 800;
    font-size: 24px;
  }

  #stage {
    padding: 10px;
    border-radius: 10px;
    background-color: var(--component-bg-color);
    border: 1px solid var(--component-bg-color);

    #stageTable {
      border-collapse: separate;
      border-spacing: 0;
      border-radius: 10px;
      white-space: nowrap;
      width: 100%;

      tr {
        height: 36px;

        th {
          color: var(--secondary);
          text-align: left;
        }
      }

      th,
      td {
        border-bottom: 1px solid var(--divider-color);
        padding: 8px;
        font-weight: 500;
      }

      tr:first-child th,
      tr:first-child td {
        border-top: 1px solid var(--divider-color); /* Add top border to the first row */
      }

      td:first-child,
      th:first-child {
        border-left: 1px solid var(--divider-color); /* Remove left border from the first column */
      }

      td:last-child,
      th:last-child {
        border-right: 1px solid var(--divider-color); /* Remove right border from the last column */
      }
    }
  }

  #user {
    padding: 10px;
    border-radius: 10px;
    background-color: var(--component-bg-color);
    border: 1px solid var(--component-bg-color);

    #userTable {
      border-collapse: separate;
      border-spacing: 0;
      border-radius: 10px;
      white-space: nowrap;
      width: 100%;

      tr {
        height: 36px;

        th {
          color: var(--secondary);
          text-align: left;
        }
      }

      th,
      td {
        border-bottom: 1px solid var(--divider-color);
        padding: 8px;
        font-weight: 500;
      }

      tr:nth-child(odd) td {
        background-color: #f5effa; /* Set the background color for odd rows */
      }

      tr:nth-child(even) td {
        background-color: #eafcff; /* Set the background color for even rows */
      }

      tr:first-child th,
      tr:first-child td {
        border-top: 1px solid var(--divider-color); /* Add top border to the first row */
      }

      td:first-child,
      th:first-child {
        border-left: 1px solid var(--divider-color); /* Remove left border from the first column */
      }

      td:last-child,
      th:last-child {
        border-right: 1px solid var(--divider-color); /* Remove right border from the last column */
      }
    }
  }

  #workflow {
    padding: 10px;
    border-radius: 10px;
    background-color: var(--component-bg-color);
    border: 1px solid var(--component-bg-color);

    #workflowTable {
      border-collapse: separate;
      border-spacing: 0;
      border-radius: 10px;
      white-space: nowrap;
      width: 100%;

      tr {
        height: 36px;

        th {
          color: var(--secondary);
          text-align: left;
        }
      }

      th,
      td {
        border-bottom: 1px solid var(--divider-color);
        padding: 8px;
        font-weight: 500;
      }

      tr:first-child th,
      tr:first-child td {
        border-top: 1px solid var(--divider-color); /* Add top border to the first row */
      }

      td:first-child,
      th:first-child {
        border-left: 1px solid var(--divider-color); /* Remove left border from the first column */
      }

      td:last-child,
      th:last-child {
        border-right: 1px solid var(--divider-color); /* Remove right border from the last column */
      }
    }
  }
}
</style>
